const ID_TOKEN_KEY = "id_token";
const TIMER_KEY = "timer";

export const getToken = () => {
    return window.localStorage.getItem(ID_TOKEN_KEY);
};

export const saveToken = token => {
    window.localStorage.setItem(ID_TOKEN_KEY, token);
};

export const setTimer = () => {
    window.localStorage.setItem(TIMER_KEY, 1);
}

export const getTimer = () => {
    return window.localStorage.getItem(TIMER_KEY);
}

export const destroyToken = () => {
    window.localStorage.removeItem(ID_TOKEN_KEY);
    window.localStorage.removeItem(TIMER_KEY);
};

export default { getToken, saveToken, destroyToken, setTimer, getTimer };