import ApiService from "@/core/services/api.service";

// action types
export const GET_HOME_DATA = "getHomeData";
export const GET_QUESTION_DATA = "getQuestionData";
export const GET_DOC_DATA = "getDocData";
export const UPDATE_PROGRESS = "updateProgress";

// mutation types
export const SET_HOME_DATA = "setHomeData";
export const SET_QUESTION_DATA = "setQuestionData";
export const SET_DOC_DATA = "setDocData";
export const SET_ERROR = "setError";

const state = {
    errors: null,
    homeData: {},
    docData: {},
    questionData: {},
};

const getters = {
    homeData(state) {
        return state.homeData;
    },
    questionData(state) {
        return state.questionData;
    },
    docData(state) {
        return state.docData;
    }
};

const actions = {
    [GET_HOME_DATA](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("documents/home", params)
                .then(({ data }) => {
                    context.commit(SET_HOME_DATA, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [GET_DOC_DATA](context, id) {
        return new Promise((resolve, reject) => {
            ApiService.get("document/" + id)
                .then(({ data }) => {
                    context.commit(SET_DOC_DATA, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [UPDATE_PROGRESS](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("document/progress", params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [GET_QUESTION_DATA](context, lesson) {
        return new Promise((resolve, reject) => {
            ApiService.get("lessons/" + lesson + "/questions")
                .then(({ data }) => {
                    context.commit(SET_QUESTION_DATA, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_HOME_DATA](state, data) {
        state.homeData = data;
    },
    [SET_DOC_DATA](state, data) {
        state.docData = data;
    },
    [SET_QUESTION_DATA](state, data) {
        state.questionData = data;
    },
    [SET_ERROR](state, error) {
        state.errors = [];
        state.errors.push(error);
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};