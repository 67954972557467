import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_ERROR = "setError";
export const SET_START_TIMER = "setStartTimer";

const state = {
    errors: null,
    user: {},
    recordingTime: 0,
    isAuthenticated: !!JwtService.getToken()
};

const getters = {
    currentUser(state) {
        return state.user;
    },
    isAuthenticated(state) {
        return state.isAuthenticated;
    },
    getRecordingStatus(state) {
        return state.recordingTime;
    }
};

const actions = {
    [LOGIN](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("auth/login", credentials)
                .then(({ data }) => {
                    context.commit(SET_AUTH, data.data);
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log(response)
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [LOGOUT](context) {
        context.commit(PURGE_AUTH);
    },
    [REGISTER](context, credentials) {
        return new Promise((resolve, reject) => {
            ApiService.post("auth/register", credentials)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
    [VERIFY_AUTH](context) {
        if (JwtService.getToken()) {
            ApiService.setHeader();
            ApiService.get("account")
                .then(({ data }) => {
                    context.commit(SET_AUTH, data.data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.errors);
                    context.commit(PURGE_AUTH);

                });
        } else {
            context.commit(PURGE_AUTH);
        }
    },
    [UPDATE_USER](context, payload) {
        const { email, username, password, image, bio } = payload;
        const user = { email, username, bio, image };
        if (password) {
            user.password = password;
        }

        return ApiService.put("user", user).then(({ data }) => {
            context.commit(SET_AUTH, data);
            return data;
        });
    }
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = [];
        state.errors.push(error);
    },
    [SET_AUTH](state, user) {
        state.isAuthenticated = true;
        user.percent = Math.round(user.progress / 15 * 100);
        user.currentAvatar = [];
        for (const avatars of Object.values(user.avatars)) {
            for (let item of avatars) {
                if (user.avatar.includes(item._id)) {
                    user.currentAvatar.push(item);
                }
            }
        }

        state.user = user;
        state.errors = {};
        JwtService.saveToken(state.user.token);
    },
    [SET_START_TIMER](state) {
        if (state.recordingTime > 0) {
            return;
        }
        state.recordingTime += 1;
        setInterval(
            function() {
                ApiService.get("/account/autonomoustime/update").then(({ data }) => {
                    if (data.data.over) {
                        state.recordingTime += 1;
                    }
                });
            },
            30000,
        )
    },
    [PURGE_AUTH](state) {
        state.isAuthenticated = false;
        state.user = {};
        state.errors = {};
        JwtService.destroyToken();
    }
};

export default {
    state,
    actions,
    mutations,
    getters
};