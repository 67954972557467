import ApiService from "@/core/services/api.service";

// action types
export const UPDATE_AVATAR = "updateAvatar";
export const PURCHASE_AVATAR = "purchaseAvatar";
export const SET_ERROR = "setError";


const state = {
    errors: null,
};

const getters = {

};

const actions = {
    [UPDATE_AVATAR](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("account/avatar/update", params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },

    [PURCHASE_AVATAR](context, params) {
        return new Promise((resolve, reject) => {
            ApiService.post("account/avatar/purchase", params)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    context.commit(SET_ERROR, response.data.message);
                    reject(response);
                });
        });
    },
};

const mutations = {
    [SET_ERROR](state, error) {
        state.errors = [];
        state.errors.push(error);
    },
};

export default {
    state,
    actions,
    mutations,
    getters
};